module.exports = [{
      plugin: require('/vercel/workpath0/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography.js"},
    },{
      plugin: require('/vercel/workpath0/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-130656267-1","head":true,"anonymize":true,"respectDNT":true,"sampleRate":5,"siteSpeedSampleRate":10},
    },{
      plugin: require('/vercel/workpath0/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/vercel/workpath0/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
